import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import { useFadeOut } from '../../hooks/fadeOut';
import styles from './styles.module.scss';
import Button from '../Button';

import CloseIcon from '!svg-react-loader?name=CloseIcon!../../../assets/icons/close.svg';

export const Dialog: React.FC<Props> = (props) => {
  const {
    animationDuration,
    buttons,
    className,
    closeable,
    modifiers,
    showCloseButton,
    children,
    onClose,
    show,
    title
  } = props;
  const duration = (animationDuration || 0) + 'ms';
  const shouldFadeOut = useFadeOut(show);

  return (
    <div className={styles.container}>
      <aside
        arial-modal="true"
        className={classNames(
          modifiers ? modifiers.split(' ').map((m: string) => styles[m]) : null,
          styles.dialog,
          {
            [styles.show]: show,
            [styles.fade_out]: !show && shouldFadeOut
          },
          className
        )}
        role="dialog"
        style={{
          transitionDuration: duration
        }}
        tabIndex={-1}
      >
        <div className={styles.header}>
          <h1
            className={classNames(
              styles.title,
              'text'
            )}
          >
            {title}
          </h1>
          {showCloseButton &&
            <Button
              className={styles.close}
              disabled={closeable !== undefined
                ? !closeable
                : false
              }
              modifiers="primary clear small no-text no-margin no-padding-x"
              onClick={onClose}
              title="Close"
            >
              <span className="text">
                Close
              </span>
              <CloseIcon />
            </Button>
          }
        </div>

        <div className={styles.body}>
          <div className={styles.inner}>
            {children}
          </div>
        </div>

        {buttons && buttons.length > 0 &&
          <div className={styles.footer}>
            {buttons.map((button) => button)}
          </div>
        }

        {!buttons &&
          <div className={styles.footer}>
            <Button
              modifiers="primary"
              onClick={onClose}
            >
              OK
            </Button>
          </div>
        }
      </aside>
    </div>
  );
};