import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { isBrowser } from '../../helpers/utils';
import { Props } from './types';
import styles from './styles.module.scss';

const Nav: React.FC<Props> = (props) => {
  const { className, highlight, location } = props;
  const [currentHash, setCurrentHash] = useState<string>('');
  const scrollPosition = useRef(0);
  const scheduledAnimationFrame = useRef(false);

  const readAndUpdatePage = () => {
    scheduledAnimationFrame.current = false;

    let nextHash = currentHash;
    const start = document.getElementById('start');
    const what = document.getElementById('what');
    const why = document.getElementById('why');

    if (start && what && why) {
      if (scrollPosition.current >= why.offsetTop) {
        nextHash = '#why';
      } else if (scrollPosition.current >= what.offsetTop) {
        nextHash = '#what';
      } else {
        nextHash = '#start';
      }
    }

    if (nextHash !== currentHash) {
      setCurrentHash(nextHash);
    }
  };

  const handleScroll = () => {
    scrollPosition.current = window.pageYOffset;

    if (scheduledAnimationFrame.current) {
      return;
    }

    scheduledAnimationFrame.current = true;
    requestAnimationFrame(readAndUpdatePage);
  };

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (isBrowser && highlight) {
      window.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (isBrowser && highlight) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [highlight]);

  useEffect(() => {
    const element = document.getElementById(location.hash.replace('#', ''));

    setTimeout(() => {
      window.scrollTo({
        behavior: element
          ? 'smooth'
          : 'auto',
        top: element
          ? element.offsetTop
          : 0
      });
    }, 100);
  }, [location]);

  useEffect(() => {
    setCurrentHash(location.hash);
  }, []);

  return (
    <nav className={className}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Link
            className={classNames(
              styles.link,
              { [styles.active]: highlight && currentHash === '#start' }
            )}
            onClick={onClose}
            title="Start"
            to="/#start"
          >
            Start
          </Link>
        </li>

        <li className={styles.item}>
          <Link
            className={classNames(
              styles.link,
              { [styles.active]: highlight && currentHash === '#what' }
            )}
            onClick={onClose}
            title="What is it?"
            to="/#what"
          >
            What is it?
          </Link>
        </li>

        <li className={styles.item}>
          <Link
            className={classNames(
              styles.link,
              { [styles.active]: highlight && currentHash === '#why' }
            )}
            onClick={onClose}
            title="What do I get?"
            to="/#why"
          >
            What do I get?
          </Link>
        </li>

        <li className={styles.item}>
          <a
            className={styles.link}
            href="https://app.thedatabakery.io"
            onClick={onClose}
            rel="noreferrer noopener"
            target="_blank"
            title="Digital Tool"
          >
            Digital Tool <span className={styles.beta}>(Beta)</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default withRouter(Nav);