import React, { memo } from 'react';

import styles from './styles.module.scss';

const ScreenDebugger: React.FC = () => (
  process.env.NODE_ENV !== 'production'
    ? <div className={styles.debugger}>size: </div>
    : null
);

export default memo(ScreenDebugger);