import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import * as dimension from './dimension';
import * as routeHistory from './routeHistory';

export interface State {
  dimension: dimension.DimensionState;
  routeHistory: routeHistory.RouteHistoryState;
}

export const rootState: State = {
  dimension: dimension.initialState,
  routeHistory: routeHistory.initialState,
};

export const rootReducer = (history: History<any>) => combineReducers<State>({
  dimension: dimension.reducer,
  routeHistory: routeHistory.routeHistoryReducer,
  router: connectRouter(history),
} as any);