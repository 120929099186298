import React from 'react';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';

const Backdrop: React.FC<Props> = (props) => {
  const { className, dimmable, dimmed } = props;
  const duration = (props.animationDuration || 0) + 'ms';
  return (
    <div
      className={classNames(
        styles.backdrop,
        {
          [styles.transparent]: !dimmable,
          [styles.dimmed]: dimmed
        },
        className
      )}
      onClick={props.onClick}
      style={
        {
          animationDuration: duration,
          transitionDuration: duration
        }
      }
    />
  );
};

export default Backdrop;