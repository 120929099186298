import React from 'react';

import { ContainerProps } from './types';
import { Dialog } from './component';
import Modal from '../Modal';

const DialogContainer: React.FC<ContainerProps> = (props) => (
  <Modal
    component={Dialog}
    {...props}
  />
);

export default DialogContainer;