import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Props } from './types';
import styles from './styles.module.scss';
import Button from '../Button';
import MenuModal from '../MenuModal';
import Nav from '../Nav';
import Wrapper from '../Wrapper';

import HamburgerIcon from '!svg-react-loader?name=HamburgerIcon!../../../assets/icons/menu.svg';

const Header: React.FC<Props> = (props) => {
  const { className } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <>
      <Wrapper
        as="header"
        className={classNames(
          styles.block,
          className
        )}
      >
        <div className={styles.row}>
          <Link
            className={classNames(
              styles.link,
              'text'
            )}
            title="The Data Bakery"
            to="/"
          >
            The Data Bakery
          </Link>

          <div className={styles.right}>
            <Nav
              className={styles.nav}
              highlight={true}
            />

            <Button
              className={styles.hamburger}
              modifiers="action primary clear large no-text"
              onClick={toggleMenu}
              title="Show menu"
            >
              <HamburgerIcon className={styles.icon} />
            </Button>
          </div>
        </div>
      </Wrapper>

      <MenuModal
        isOpen={isMenuOpen}
        onClose={toggleMenu}
      />
    </>
  );
};

export default Header;