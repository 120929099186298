import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';

import '../../../assets/styles/styles.scss';
import styles from './styles.module.scss';
import Header from '../Header';
import ScreenDebugger from '../ScreenDebugger';

const fallback = <div>loading...</div>;

const FrontPage = loadable(() => import('../../pages/FrontPage'), { fallback });
const NotFoundPage = loadable(() => import('../../pages/NotFoundPage'), { fallback });

const App: React.FC = () => {
  return (
    <>
      <Helmet
        defaultTitle="The Data Bakery"
        htmlAttributes={{
          dir: 'ltr',
          lang: 'en',
        }}
        titleTemplate="%s | The Data Bakery"
      >
        <meta charSet="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="description" content="" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" href="/favicon-16x16.png" sizes="16x16" />
        <link rel="icon" href="/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" href="/favicon-64x64.png" sizes="64x64" />
        <link rel="icon" href="/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" href="/favicon-128x128.png" sizes="128x128" />
        <link rel="icon" href="/favicon-152x152.png" sizes="152x152" />
        <link rel="icon" href="/favicon-167x167.png" sizes="167x167" />
        <link rel="icon" href="/favicon-180x180.png" sizes="180x180" />
        <link rel="icon" href="/favicon-192x192.png" sizes="192x192" />
        <link rel="icon" href="/favicon-196x196.png" sizes="196x196" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
        <meta name="msapplication-TileColor" content="#00C995" />
        <meta name="theme-color" content="#00C995" />
        <meta name="application-name" content="The Data Bakery" />
        <meta name="apple-mobile-web-app-title" content="The Data Bakery" />
        <meta property="og:site_name" content="The Data Bakery" />
        <meta property="og:title" content="The Data Bakery" />
        <meta property="og:locale" content="sv-SE" />
      </Helmet>

      <div className={styles.container}>
        <Header />

        <main className={styles.main}>
          <Switch>
            <Route exact={true} path="/" component={FrontPage} />
            <Route path="/*" component={NotFoundPage} />
          </Switch>
        </main>
      </div>

      <ScreenDebugger />
    </>
  );
};

export default App;