import { createStore, applyMiddleware, compose, Store } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';

import { rootReducer, rootState, State } from './rootReducer';
import { promiseMiddleware } from '../middlewares/promise';

const configureStore = (preloadedState: State | null, history: History<any>): Store<State, any> => {
  const initialState: State = preloadedState ? preloadedState : rootState;
  const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window !== 'undefined'
    && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history),
        promiseMiddleware
      )
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
